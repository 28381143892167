<template>
    <div>
        <vs-table
            search 
            stripe 
            border 
            description 
            :sst="true" 
            :data="table.data" 
            :max-items="table.length"
            :total="table.total" 
            @search="handleSearch" 
            @change-page="handleChangePage" 
            @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>

            <template v-if="status === 'Open'" slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Work Order ID</vs-th>
                <vs-th sort-key="">Warehouse</vs-th>
                <vs-th sort-key="">Request Date</vs-th>
                <vs-th sort-key="">Execute Date</vs-th>
                <vs-th sort-key="">BoM ID</vs-th>
                <vs-th sort-key="">Parent SKU Data</vs-th>
                <vs-th sort-key="">Child SKU Data</vs-th>
                <vs-th sort-key="">Status</vs-th>
            </template>

            <template v-if="status === 'Picking'" slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Work Order ID</vs-th>
                <vs-th sort-key="">Warehouse</vs-th>
                <vs-th sort-key="">Request Date</vs-th>
                <vs-th sort-key="">Execute Date</vs-th>
                <vs-th sort-key="">BoM ID</vs-th>
                <vs-th sort-key="">Parent SKU Data</vs-th>
                <vs-th sort-key="">Child SKU Data</vs-th>
            </template>

            <template v-if="status === 'Execute'" slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Work Order ID</vs-th>
                <vs-th sort-key="">Warehouse</vs-th>
                <vs-th sort-key="">Request Date</vs-th>
                <vs-th sort-key="">Execute Date</vs-th>
                <vs-th sort-key="">BoM ID</vs-th>
                <vs-th sort-key="">Parent SKU Data</vs-th>
                <vs-th sort-key="">Child SKU Data</vs-th>
            </template>

            <template v-if="status === 'Putaway'" slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Work Order ID</vs-th>
                <vs-th sort-key="">Warehouse</vs-th>
                <vs-th sort-key="">Request Date</vs-th>
                <vs-th sort-key="">Execute Date</vs-th>
                <vs-th sort-key="">BoM ID</vs-th>
                <vs-th sort-key="">Parent SKU Data</vs-th>
                <vs-th sort-key="">Child SKU Data</vs-th>
            </template>

            <template v-if="status === 'Complete'" slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Work Order ID</vs-th>
                <vs-th sort-key="">Warehouse</vs-th>
                <vs-th sort-key="">Request Date</vs-th>
                <vs-th sort-key="">Execute Date</vs-th>
                <vs-th sort-key="">BoM ID</vs-th>
                <vs-th sort-key="">Parent SKU Data</vs-th>
                <vs-th sort-key="">Child SKU Data</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td v-if="status === 'Open'" class="align-top">
                        <div class="vx-input-group flex">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye"
                            title="Detail" style="margin-right: 5px" @click="handleDetail(tr.id)" :disabled="tr.plan_id === 0" />
                            <vs-button size="small" color="#0040ff" icon-pack="feather" icon="icon-settings"
                            title="Picking Plan" style="margin-right: 5px" @click="handlePickingPlan(tr.id)" />
                            <vs-button size="small" color="#ff00ff" icon-pack="feather" icon="icon-printer"
                            title="Print" style="margin-right: 5px" @click="handlePrint(tr.id)" :disabled="tr.plan_id === 0" />
                            <vs-button size="small" color="success" icon-pack="feather" icon="icon-check"
                            title="Picking" style="margin-right: 5px" @click="handlePicking(tr.id)" :disabled="tr.plan_id === 0" />
                        </div>
                    </vs-td>
                    <vs-td v-if="status === 'Open'" class="align-top">{{ tr.code }}</vs-td>
                    <vs-td v-if="status === 'Open'" class="align-top">{{ tr.warehouse_name }}</vs-td>
                    <vs-td v-if="status === 'Open'" class="align-top">{{ formatDate(tr.request_date) }}</vs-td>
                    <vs-td v-if="status === 'Open'" class="align-top">{{ formatDate(tr.execution_date) }}</vs-td>
                    <vs-td v-if="status === 'Open'" class="align-top">{{ tr.item_bom_code }}</vs-td>
                    <vs-td v-if="status === 'Open'" class="align-top">{{ tr.item_name }}</vs-td>
                    <vs-td v-if="status === 'Open'">
                        <vs-tr :data="trx" :key="indextrx" v-for="(trx, indextrx) in tr.child_data">
                            <vs-td>{{ trx.item_name }}</vs-td>
                        </vs-tr>
                    </vs-td>
                    <vs-td v-if="status === 'Open'" class="align-top">{{ (tr.plan_id) ? 'Planned' : 'Unplanned' }}</vs-td>

                    <vs-td v-if="status === 'Picking'" class="align-top">
                        <div class="vx-input-group flex">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-edit"
                            title="Edit" style="margin-right: 5px" @click="handleActual(tr.id)" />
                            <vs-button size="small" color="success" icon-pack="feather" icon="icon-check"
                            title="Execute" style="margin-right: 5px" @click="handleExecuteActual(tr.id)" :disabled="tr.status_picking === 0" />
                        </div>
                    </vs-td>
                    <vs-td v-if="status === 'Picking'" class="align-top">{{ tr.code }}</vs-td>
                    <vs-td v-if="status === 'Picking'" class="align-top">{{ tr.warehouse_name }}</vs-td>
                    <vs-td v-if="status === 'Picking'" class="align-top">{{ formatDate(tr.request_date) }}</vs-td>
                    <vs-td v-if="status === 'Picking'" class="align-top">{{ formatDate(tr.execution_date) }}</vs-td>
                    <vs-td v-if="status === 'Picking'" class="align-top">{{ tr.item_bom_code }}</vs-td>
                    <vs-td v-if="status === 'Picking'" class="align-top">{{ tr.item_name }}</vs-td>
                    <vs-td v-if="status === 'Picking'">
                        <vs-tr :data="trx" :key="indextrx" v-for="(trx, indextrx) in tr.child_data">
                            <vs-td>{{ trx.item_name }}</vs-td>
                        </vs-tr>
                    </vs-td>

                    <vs-td v-if="status === 'Execute'" class="align-top">
                        <div class="vx-input-group flex">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-edit"
                            title="Edit" style="margin-right: 5px" @click="handleExecuteForm(tr.id)" />
                            <vs-button size="small" color="#ff00ff" icon-pack="feather" icon="icon-printer"
                            title="Print" style="margin-right: 5px" @click="handlePrint(tr.id)" :disabled="tr.actual_qty === 0" />
                            <vs-button size="small" color="success" icon-pack="feather" icon="icon-check"
                            title="Complete" style="margin-right: 5px" @click="handleCompleteExecute(tr.id)" :disabled="tr.actual_qty === 0" />
                        </div>
                    </vs-td>
                    <vs-td v-if="status === 'Execute'" class="align-top">{{ tr.code }}</vs-td>
                    <vs-td v-if="status === 'Execute'" class="align-top">{{ tr.warehouse_name }}</vs-td>
                    <vs-td v-if="status === 'Execute'" class="align-top">{{ formatDate(tr.request_date) }}</vs-td>
                    <vs-td v-if="status === 'Execute'" class="align-top">{{ formatDate(tr.execution_date) }}</vs-td>
                    <vs-td v-if="status === 'Execute'" class="align-top">{{ tr.item_bom_code }}</vs-td>
                    <vs-td v-if="status === 'Execute'" class="align-top">{{ tr.item_name }}</vs-td>
                    <vs-td v-if="status === 'Execute'">
                        <vs-tr :data="trx" :key="indextrx" v-for="(trx, indextrx) in tr.child_data">
                            <vs-td>{{ trx.item_name }}</vs-td>
                        </vs-tr>
                    </vs-td>

                    <vs-td v-if="status === 'Putaway'" class="align-top">
                        <div class="vx-input-group flex">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-edit"
                            title="Edit" style="margin-right: 5px" @click="handlePutawayForm(tr.id)" />
                            <vs-button size="small" color="#ff00ff" icon-pack="feather" icon="icon-printer"
                            title="Print" style="margin-right: 5px" @click="handlePrint(tr.id)" :disabled="tr.putaway_id === 0" />
                            <vs-button size="small" color="success" icon-pack="feather" icon="icon-check"
                            title="Complete" style="margin-right: 5px" @click="handleCompletePutaway(tr.id)" :disabled="tr.putaway_id === 0" />
                        </div>
                    </vs-td>
                    <vs-td v-if="status === 'Putaway'" class="align-top">{{ tr.code }}</vs-td>
                    <vs-td v-if="status === 'Putaway'" class="align-top">{{ tr.warehouse_name }}</vs-td>
                    <vs-td v-if="status === 'Putaway'" class="align-top">{{ formatDate(tr.request_date) }}</vs-td>
                    <vs-td v-if="status === 'Putaway'" class="align-top">{{ formatDate(tr.execution_date) }}</vs-td>
                    <vs-td v-if="status === 'Putaway'" class="align-top">{{ tr.item_bom_code }}</vs-td>
                    <vs-td v-if="status === 'Putaway'" class="align-top">{{ tr.item_name }}</vs-td>
                    <vs-td v-if="status === 'Putaway'">
                        <vs-tr :data="trx" :key="indextrx" v-for="(trx, indextrx) in tr.child_data">
                            <vs-td>{{ trx.item_name }}</vs-td>
                        </vs-tr>
                    </vs-td>

                    <vs-td v-if="status === 'Complete'" class="align-top">
                        <div class="vx-input-group flex">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye"
                            title="Detail" style="margin-right: 5px" @click="handleDetail(tr.id)" />
                            <vs-button size="small" color="success" icon-pack="feather" icon="icon-copy"
                            title="Copy" style="margin-right: 5px" @click="handleCopyDocument(tr.id)" />
                        </div>
                    </vs-td>
                    <vs-td v-if="status === 'Complete'" class="align-top">{{ tr.code }}</vs-td>
                    <vs-td v-if="status === 'Complete'" class="align-top">{{ tr.warehouse_name }}</vs-td>
                    <vs-td v-if="status === 'Complete'" class="align-top">{{ formatDate(tr.request_date) }}</vs-td>
                    <vs-td v-if="status === 'Complete'" class="align-top">{{ formatDate(tr.execution_date) }}</vs-td>
                    <vs-td v-if="status === 'Complete'" class="align-top">{{ tr.item_bom_code }}</vs-td>
                    <vs-td v-if="status === 'Complete'" class="align-top">{{ tr.item_name }}</vs-td>
                    <vs-td v-if="status === 'Complete'">
                        <vs-tr :data="trx" :key="indextrx" v-for="(trx, indextrx) in tr.child_data">
                            <vs-td>{{ trx.item_name }}</vs-td>
                        </vs-tr>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage"/>
    </div>
</template>

<script>
import moment from "moment";

export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        workOrderId: {
            type: Number,
        },
        executionDate: {
            type: String,
        },
        status: {
            type: String,
            default: "Open",
        },
    },
    data() {
        return {
            deleteId: null,
            action: null,
            table: this.tableDefaultState(),
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/work-order-execution/data-table", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        work_order_id: this.workOrderId 
                            ? this.workOrderId
                            : null,
                        execution_date: this.executionDate 
                            ? this.executionDate 
                            : null,
                        status: this.status,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleDetail(id) {
            if (this.status === 'Open') {
                this.$router.push({
                    name: "work-order-execution-picking-plan-detail",
                    params: { id: id },
                });
            } 
            else if (this.status === 'Complete') {
                this.$router.push({
                    name: "work-order-execution-complete-detail",
                    params: { id: id },
                });
            }
        },
        handlePickingPlan(id) {
            this.$router.push({
                name: "work-order-execution-picking",
                params: { id: id },
            });
        },
        handlePrint(id) {
            if (this.status === 'Open') {
                const routeData = this.$router.resolve({
                    name: "work-order.execution.print-picking-plan",
                    params: { id: id },
                });
                window.open(routeData.href, "_blank");
            } else if (this.status === 'Execute') {
                const routeData = this.$router.resolve({
                    name: "work-order.execution.print-execute",
                    params: { id: id },
                });
                window.open(routeData.href, "_blank");
            } else if (this.status === 'Putaway') {
                const routeData = this.$router.resolve({
                    name: "work-order.execution.print-putaway",
                    params: { id: id },
                });
                window.open(routeData.href, "_blank");
            }
        },
        handlePicking(id) {
            this.deleteId = id
            this.action = "PickingOpen"
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to process to picking?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .post("/api/wms/v1/work-order-execution/action-button/" + this.deleteId + "/" + this.action)
                        .then(resp => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    title: "Success",
                                    text: "Document is processed",
                                    color: "success",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check"
                                });
                                this.getData();
                                this.$vs.loading.close();
                            } else {
                                this.$vs.notify({
                                    title: "Warning",
                                    text: resp.message,
                                    color: "danger",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle"
                                });
                                this.$vs.loading.close();
                            }
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                }
            });
        },
        handleActual(id) {
            this.$router.push({
                name: "work-order-execution-picking-actual",
                params: { id: id },
            });
        },
        handleExecuteActual(id) {
            this.deleteId = id
            this.action = "PickingActual"
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to process to execute?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .post("/api/wms/v1/work-order-execution/action-button/" + this.deleteId + "/" + this.action)
                        .then(resp => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    title: "Success",
                                    text: "Document is processed",
                                    color: "success",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check"
                                });
                                this.getData();
                                this.$vs.loading.close();
                            } else {
                                this.$vs.notify({
                                    title: "Warning",
                                    text: resp.message,
                                    color: "danger",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle"
                                });
                                this.$vs.loading.close();
                            }
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                }
            });
        },
        handleExecuteForm(id) {
            this.$router.push({
                name: "work-order-execution-execute-form",
                params: { id: id },
            });
        },
        handleCompleteExecute(id) {
            this.deleteId = id
            this.action = "CompleteExecute"
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to process to putaway?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .post("/api/wms/v1/work-order-execution/action-button/" + this.deleteId + "/" + this.action)
                        .then(resp => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    title: "Success",
                                    text: "Document is executed",
                                    color: "success",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check"
                                });
                                this.getData();
                                this.$vs.loading.close();
                            } else {
                                this.$vs.notify({
                                    title: "Warning",
                                    text: resp.message,
                                    color: "danger",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle"
                                });
                                this.$vs.loading.close();
                            }
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                }
            });
        },
        handlePutawayForm(id) {
            this.$router.push({
                name: "work-order-execution-putaway-form",
                params: { id: id },
            });
        },
        handleCompletePutaway(id) {
            this.deleteId = id
            this.action = "CompletePutaway"
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to complete this document?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .post("/api/wms/v1/work-order-execution/action-button/" + this.deleteId + "/" + this.action)
                        .then(resp => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    title: "Success",
                                    text: "Document is executed",
                                    color: "success",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check"
                                });
                                this.getData();
                                this.$vs.loading.close();
                            } else {
                                this.$vs.notify({
                                    title: "Warning",
                                    text: resp.message,
                                    color: "danger",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle"
                                });
                                this.$vs.loading.close();
                            }
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                }
            });
        },
        handleCopyDocument(id) {
            this.deleteId = id
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to copy this document?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .post("/api/wms/v1/work-order-execution/copy-document/" + this.deleteId)
                        .then(resp => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    title: "Success",
                                    text: "New copied document is created",
                                    color: "success",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check"
                                });
                                this.getData();
                                this.$vs.loading.close();
                            } else {
                                this.$vs.notify({
                                    title: "Warning",
                                    text: resp.message,
                                    color: "danger",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle"
                                });
                                this.$vs.loading.close();
                            }
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                }
            });
        },
        handleCobaDulu() {
            this.$http.post("/api/wms/v1/work-order-execution/bansos-aqua")
        }
    },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>